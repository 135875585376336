import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useAuth } from "@koble/hooks";
import externalLinks from "@koble/utils/src/externalLinks";
import { ItemType } from "antd/es/menu/interface";

import useUserStudentProfile from "@/common/UserStudentProfileContext/useUserStudentProfile";

const useUserStudentLayoutLowerMenuItems = () => {
  const { userStudent } = useUserStudentProfile();
  const { identityClaims } = useAuth();

  if (!identityClaims) return [];

  const menu: ItemType[] = [
    {
      icon: <InfoCircleOutlined />,
      key: "help",
      label: (
        <a
          href={
            externalLinks.userStudentSupportKobleTypeFormLink +
            userStudent?.userStudentId
          }
          target="_blank"
          rel="noreferrer"
        >
          Ayuda
        </a>
      ),
    },
  ];

  return menu;
};

export default useUserStudentLayoutLowerMenuItems;
