import { useTheme } from "@koble/hooks";
import { ConfigProvider, Menu } from "antd";

import useCommonLayout from "../useCommonLayout";

const CommonLayoutSideBarMenu = () => {
  const { theme } = useTheme();
  const { upperMenuItemsWithBadges, selectedMenuKeys, openKeys } =
    useCommonLayout();

  return (
    <ConfigProvider theme={theme}>
      <div id="common-layout-side-bar-menu">
        <Menu
          selectedKeys={selectedMenuKeys}
          defaultOpenKeys={openKeys}
          style={{
            border: "none",
          }}
          items={upperMenuItemsWithBadges}
          mode="inline"
        />
      </div>
    </ConfigProvider>
  );
};

export default CommonLayoutSideBarMenu;
