import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { assets } from "@koble/assets/assets";
import { Col, Row, Typography } from "antd";

const CommonCompletionProfileBanner = ({
  onProfileCompletionBannerClose,
  isMobile,
  hasBorderRadius,
}: {
  onProfileCompletionBannerClose: () => void;
  isMobile: boolean;
  hasBorderRadius: boolean;
}) => {
  return (
    <div
      key="profile-completion-banner"
      style={{
        padding: "10px 15px",
        backgroundColor: "#8e44ad",
        color: "white",
        borderBottomLeftRadius: hasBorderRadius ? 10 : 0,
        borderBottomRightRadius: hasBorderRadius ? 10 : 0,
      }}
    >
      <Row style={{ justifyContent: "space-between" }}>
        <Col style={{ display: "flex", alignItems: "center" }}>
          <img alt="Kobot" height={20} src={assets.KOBOT_HAPPY_EXTRA_SMALL} />
          {isMobile ? (
            <Typography.Text style={{ color: "white", marginLeft: 10 }}>
              ¡Aún puedes mejorar tu perfil!
            </Typography.Text>
          ) : (
            <Typography.Text style={{ color: "white", marginLeft: 10 }}>
              ¡Aún puedes mejorar tu perfil! Un perfil completo tiene 70% más
              probabilidad de conseguir trabajo
            </Typography.Text>
          )}
        </Col>

        <Col style={{ display: "flex", alignItems: "center" }}>
          <a href="/profile-completion" style={{ marginRight: 10 }}>
            {isMobile ? (
              <Typography.Text style={{ color: "white" }}>
                Mejorar
              </Typography.Text>
            ) : (
              <Typography.Text style={{ color: "white" }}>
                Mejorar mi perfil
              </Typography.Text>
            )}
          </a>
          <CloseOutlined
            style={{ cursor: "pointer" }}
            onClick={onProfileCompletionBannerClose}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CommonCompletionProfileBanner;
