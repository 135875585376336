import React from "react";
import {
  BellOutlined,
  LoginOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import { useAuth, useNotificationWebSocket } from "@koble/hooks";
import CommonChatPopover from "@koble/ui/src/CommonChat/CommonChatPopover";
import { CommonNotificationPopover } from "@koble/ui/src/CommonNotification";
import getUrlWithRedirectUrl from "@koble/utils/src/getUrlWithRedirectUrl";
import { Badge, Button } from "antd";

import useUserStudentProfile from "@/common/UserStudentProfileContext/useUserStudentProfile";
import config from "@/config";

const useUserStudentLayoutRightAddons = () => {
  const { identityClaims } = useAuth();
  const { isLoading, notifications, fetchData } = useNotificationWebSocket();
  const { userStudent } = useUserStudentProfile();

  const rightAddons = [];

  if (identityClaims) {
    rightAddons.push(
      <CommonNotificationPopover
        key="notification-popover"
        notifications={notifications}
        isLoading={isLoading}
        fetchData={fetchData}
      >
        <Badge count={userStudent?.unreadNotificationsCount}>
          <Button key="bell-icon" icon={<BellOutlined />} />
        </Badge>
      </CommonNotificationPopover>
    );

    rightAddons.push(
      <CommonChatPopover key="chat-popover">
        <Badge count={userStudent?.unreadChatsCount}>
          <Button icon={<MessageOutlined />} />
        </Badge>
      </CommonChatPopover>
    );
  } else {
    rightAddons.push(
      <a
        key="login-link"
        href={getUrlWithRedirectUrl(
          `${config.NEXT_PUBLIC_SSO_FRONTEND}/sign-in`
        )}
      >
        <Button icon={<LoginOutlined />}>Iniciar / Registrarse</Button>
      </a>
    );
  }

  return rightAddons;
};

export default useUserStudentLayoutRightAddons;
