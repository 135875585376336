import React, { Fragment } from "react";
import { useTheme } from "@koble/hooks";
import { ConfigProvider, Layout } from "antd";
import { ItemType } from "antd/es/menu/interface";

import {
  BannerItemType,
  CommonLayoutMenuItemType,
} from "./CommonLayoutContext";
import CommonLayoutHeader from "./CommonLayoutHeader";
import CommonLayoutProvider from "./CommonLayoutProvider";
import CommonLayoutSideBar from "./CommonLayoutSideBar";
import useCommonLayout from "./useCommonLayout";

const { Content } = Layout;
const CommonLayout = ({ children }: { children: React.ReactNode }) => {
  const { theme } = useTheme();
  const { isMobile, collapsed, sideBarWidth, banners, maxWidth } =
    useCommonLayout();

  // Sum all the heights of the banners
  const totalHeight = banners?.reduce((sum, banner) => {
    return sum + banner.height;
  }, 0);

  const height = `calc(100dvh - ${
    theme?.token.Layout?.headerHeight || 64
  }px - ${totalHeight || 0}px)`;

  const content = (
    <>
      {banners &&
        banners.map((banner, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>{banner.component}</Fragment>
        ))}
      <Content
        style={{
          padding: "0 16px",
          backgroundColor: "#F5F5F5",
          minHeight: height,
          display: "flex",
          justifyContent: "center",
          overflowY: "auto",
          marginBottom: 25,
        }}
      >
        <div
          style={{
            paddingTop: 25,
            width: "100%",
            maxWidth: maxWidth || 1400,
          }}
        >
          {children}
          <div style={{ width: "100%", height: 25 }} />
        </div>
      </Content>
    </>
  );

  return (
    <ConfigProvider theme={theme}>
      {isMobile ? (
        <Layout
          style={{
            height,
          }}
        >
          <CommonLayoutHeader />
          {content}
        </Layout>
      ) : (
        <Layout hasSider>
          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  itemMarginBlock: 8,
                  itemMarginInline: 8,
                } as any,
              },
            }}
          >
            <CommonLayoutSideBar />
          </ConfigProvider>
          <Layout
            style={{
              marginLeft: collapsed ? 80 : sideBarWidth,
              transition: "all 0.2s ease-in-out",
              height,
            }}
          >
            <CommonLayoutHeader />
            {content}
          </Layout>
        </Layout>
      )}
    </ConfigProvider>
  );
};

const CommonLayoutWrapper = ({
  children,
  leftAddons,
  rightAddons,
  mobileLeftAddons,
  mobileRightAddons,
  largeLogoUrl,
  smallLogoUrl,
  upperMenuItems,
  lowerMenuItems,
  selectedMenuKeys,
  openKeys,
  avatarComponent,
  defaultCollapsed,
  banners,
  maxWidth,
  isMobile,
}: {
  children: React.ReactNode;
  leftAddons?: React.ReactNode[];
  rightAddons?: React.ReactNode[];
  mobileLeftAddons?: React.ReactNode[];
  mobileRightAddons?: React.ReactNode[];
  largeLogoUrl: string;
  smallLogoUrl: string;
  upperMenuItems?: ItemType<CommonLayoutMenuItemType>[];
  lowerMenuItems?: ItemType[];
  selectedMenuKeys?: string[];
  openKeys?: string[];
  avatarComponent?: React.ReactNode;
  defaultCollapsed?: boolean;
  banners?: BannerItemType[];
  maxWidth?: number;
  isMobile: boolean;
}) => {
  return (
    <CommonLayoutProvider
      leftAddons={leftAddons}
      rightAddons={rightAddons}
      mobileLeftAddons={mobileLeftAddons}
      mobileRightAddons={mobileRightAddons}
      largeLogoUrl={largeLogoUrl}
      smallLogoUrl={smallLogoUrl}
      upperMenuItems={upperMenuItems}
      lowerMenuItems={lowerMenuItems}
      selectedMenuKeys={selectedMenuKeys}
      openKeys={openKeys}
      avatarComponent={avatarComponent}
      defaultCollapsed={defaultCollapsed}
      banners={banners}
      maxWidth={maxWidth}
      isMobile={isMobile}
    >
      <CommonLayout>{children}</CommonLayout>
    </CommonLayoutProvider>
  );
};

export default CommonLayoutWrapper;
