"use client";

import React, { useEffect } from "react";
import { assets } from "@koble/assets/assets";
import { ThemeProvider, useChatWebSocket } from "@koble/hooks";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import CommonLayout from "@koble/ui/src/CommonLayout";
import { theme } from "antd";
import { usePathname } from "next/navigation";

import useUserStudentLayoutAvatar from "@/common/UserStudentLayout/useUserStudentLayoutAvatar";
import useUserStudentLayoutBanners from "@/common/UserStudentLayout/useUserStudentLayoutBanners";
import useUserStudentLayoutLeftAddons from "@/common/UserStudentLayout/useUserStudentLayoutLeftAddons";
import useUserStudentLayoutLowerMenuItems from "@/common/UserStudentLayout/useUserStudentLayoutLowerMenuItems";
import useUserStudentLayoutRightAddons from "@/common/UserStudentLayout/useUserStudentLayoutRightAddons";
import useUserStudentLayoutUpperMenuItems from "@/common/UserStudentLayout/useUserStudentLayoutUpperMenuItems";
import useUserStudentProfile from "@/common/UserStudentProfileContext/useUserStudentProfile";

const UserStudentLayout = ({
  children,
  defaultCollapsed,
  closedBanners,
}: {
  children: React.ReactNode;
  defaultCollapsed?: boolean;
  closedBanners?: string;
}) => {
  const t = theme.useToken();
  const pathName = usePathname();
  const { isMobile } = useIsMobile();
  const { setUserStudent } = useUserStudentProfile();
  const { chats } = useChatWebSocket();

  // recalculate unread chats count when chats change
  useEffect(() => {
    if (chats.length > 0) {
      let unreadChatsCount = 0;

      chats.forEach((chat) => {
        unreadChatsCount +=
          chat.chatMessages.length === 0 || chat.unreadMessagesCount ? 1 : 0;
      });

      setUserStudent((prev) => {
        if (!prev) return undefined;
        return {
          ...prev,
          unreadChatsCount,
        };
      });
    }
  }, [chats]);

  const a: Record<string, string[]> = {
    home: ["/", "/home", "/home", "/inicio"],
    dashboard: ["/dashboard", "/panel-de-control"],
    talent: ["/talent", "/buscar-talento"],
    "my-jobs": ["/my-jobs", "/mis-vacantes"],
    matches: ["/matches", "/matches"],
    billing: ["/billing", "/facturacion"],
    settings: ["/settings", "/settings/security"],
    profile: ["/profile", "/perfil"],
    explore: ["/explore", "/explora"],
    "likes-received": ["/likes-received", "/interesados-en-ti"],
    chats: ["/chat"],
  };

  const selectedMenuKeys = Object.keys(a).find((key) => {
    // TODO: fix this crap
    return a[key].includes(pathName) || pathName.includes((a as any)[key]);
  });

  return (
    <ThemeProvider
      theme={{
        ...t,
        token: {
          ...t.token,
          Layout: {
            ...t.token.Layout,
            triggerHeight: 54,
          } as any,
        },
      }}
    >
      <CommonLayout
        leftAddons={useUserStudentLayoutLeftAddons()}
        rightAddons={useUserStudentLayoutRightAddons()}
        mobileRightAddons={useUserStudentLayoutRightAddons()}
        largeLogoUrl={assets.KOBLE_LOGO_COLOR_WITH_TEXT}
        smallLogoUrl={assets.KOBLE_LOGO_COLOR}
        selectedMenuKeys={selectedMenuKeys ? [selectedMenuKeys] : []}
        avatarComponent={useUserStudentLayoutAvatar()}
        defaultCollapsed={defaultCollapsed}
        upperMenuItems={useUserStudentLayoutUpperMenuItems()}
        lowerMenuItems={useUserStudentLayoutLowerMenuItems()}
        banners={useUserStudentLayoutBanners(closedBanners)}
        isMobile={isMobile}
      >
        {children}
      </CommonLayout>
    </ThemeProvider>
  );
};

export default UserStudentLayout;
