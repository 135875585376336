import { Typography } from "antd";

// x-release-please-start-version
const CommonLayoutSideBarVersionNumber = () => {
  return (
    <Typography.Text
      style={{
        color: "rgba(0, 0, 0, 0.25)",
      }}
    >
      V1.38.3
    </Typography.Text>
  );
};

export default CommonLayoutSideBarVersionNumber;
