import { useState } from "react";
import {
  CheckSquareOutlined,
  DeleteOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { Notification } from "@koble/graphql";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { Button, Card, Empty, List } from "antd";

import CommonNotificationListItem from "./CommonNotificationListItem";

const CommonNotificationContainer = ({
  notifications,
  onMarkAsRead,
  onDelete,
  isDeleting,
  isMarkingAsRead,
  selectedColor,
}: {
  notifications: Notification[];
  onMarkAsRead: (notificationId: number[]) => Promise<void>;
  onDelete: (notificationId: number[]) => Promise<void>;
  isDeleting: boolean;
  isMarkingAsRead: boolean;
  selectedColor: string;
}) => {
  const { isMobile } = useIsMobile();
  const [selectedNotifications, setSelectedNotifications] = useState<number[]>(
    []
  );

  // Check if the selectedNotifications array in notification to return the notifications that are read
  const isNotificationsAsRead = () => {
    let notificationsAsRead = 0;

    notifications.forEach((n) => {
      if (selectedNotifications.includes(n.notificationId) && !n.isRead) {
        notificationsAsRead += 1;
      }
    });
    return notificationsAsRead;
  };
  const notificationsToMarkAsReadCount = isNotificationsAsRead();
  return (
    <Card
      style={{
        borderRadius: 16,
        boxShadow: "rgb(0 0 0 / 3%) 0px 0px 19px 0px",
      }}
      styles={{ body: { padding: isMobile ? "18px 0" : "29px 0" } }}
    >
      {notifications.length > 0 && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: "15px",
            rowGap: "10px",
            paddingBottom: "5px",
            marginBottom: "16px",
            overflow: "hidden",
            marginLeft: isMobile ? 26 : 34,
            marginRight: isMobile ? 26 : 34,
          }}
        >
          <Button
            onClick={() => {
              if (selectedNotifications.length === 0) {
                setSelectedNotifications(
                  notifications.map(
                    (notification) => notification.notificationId
                  )
                );
                return;
              }
              setSelectedNotifications([]);
            }}
            icon={
              selectedNotifications.length === 0 ? (
                <PlusSquareOutlined />
              ) : (
                <MinusSquareOutlined />
              )
            }
          >
            {selectedNotifications.length === 0
              ? `Seleccionar todas (${notifications.length})`
              : `De seleccionar todas (${selectedNotifications.length})`}
          </Button>

          {selectedNotifications.length > 0 && (
            <>
              <Button
                icon={<DeleteOutlined />}
                loading={isDeleting}
                onClick={async () => {
                  await onDelete(selectedNotifications);
                  setSelectedNotifications([]);
                }}
              >
                Eliminar ({selectedNotifications.length})
              </Button>
              {notificationsToMarkAsReadCount > 0 && (
                <Button
                  icon={<CheckSquareOutlined />}
                  loading={isMarkingAsRead}
                  onClick={async () => {
                    await onMarkAsRead(selectedNotifications);
                    setSelectedNotifications([]);
                  }}
                >
                  Marcar como leídas ({notificationsToMarkAsReadCount})
                </Button>
              )}
            </>
          )}
        </div>
      )}

      {notifications.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No tienes notificaciones"
        />
      )}

      {notifications.length > 0 && (
        <List
          itemLayout="horizontal"
          dataSource={notifications}
          renderItem={(item) => (
            <CommonNotificationListItem
              notification={item}
              isChecked={selectedNotifications.includes(item.notificationId)}
              selectedColor={selectedColor}
              onCheck={(notificationId) => {
                if (selectedNotifications.includes(notificationId)) {
                  setSelectedNotifications(
                    selectedNotifications.filter(
                      (selectedNotification) =>
                        selectedNotification !== notificationId
                    )
                  );
                } else {
                  setSelectedNotifications([
                    ...selectedNotifications,
                    notificationId,
                  ]);
                }
              }}
            />
          )}
        />
      )}
    </Card>
  );
};

export default CommonNotificationContainer;
