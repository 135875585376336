import React from "react";
import {
  BellOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MessageOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { UserStudent } from "@koble/graphql";
import { useAuth } from "@koble/hooks";
import externalLinks from "@koble/utils/src/externalLinks";
import {
  percentCompleted,
  userStudentCompletionFields,
  userStudentVisibleFields,
} from "@koble/utils/src/itemCompletion";
import {
  Avatar,
  Col,
  ConfigProvider,
  Divider,
  Menu,
  Popover,
  Progress,
  Row,
  Space,
  Typography,
} from "antd";
import { ItemType, MenuItemType } from "antd/es/menu/interface";

import useUserStudentProfile from "@/common/UserStudentProfileContext/useUserStudentProfile";
import config from "@/config";

const { Title, Text } = Typography;

// TODO: Move this component to a shared library
const AvatarWithProgress = ({
  percent,
  size,
  src,
  showProgress,
  color,
}: {
  percent: number;
  size: number;
  src?: string;
  showProgress: boolean;
  color?: string;
}) => {
  return (
    <div
      style={{
        position: "relative",
        width: size,
        height: 40,
        cursor: "pointer",
      }}
    >
      {showProgress && (
        <Progress
          type="circle"
          percent={percent}
          showInfo={false}
          size={size}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 9999,
            color,
          }}
          strokeColor={color}
          strokeWidth={8}
          strokeLinecap="square"
        />
      )}
      <Avatar
        style={{
          width: size - 4,
          height: size - 4,
          position: "absolute",
          top: 2,
          left: 2,
          backgroundColor: "#F5F5F5",
        }}
        key="avatar"
        src={src}
      >
        <UserOutlined style={{ color: "#606060", fontSize: 12 }} />
      </Avatar>
    </div>
  );
};

const useUserStudentLayoutAvatar = () => {
  const { userStudent } = useUserStudentProfile();
  const { identityClaims } = useAuth();

  if (!identityClaims) return null;
  if (!userStudent) return null;

  const visiblePercent = percentCompleted<UserStudent>(
    userStudent,
    userStudentVisibleFields
  );

  const completedPercent = percentCompleted<UserStudent>(
    userStudent,
    userStudentCompletionFields
  );

  let avatarComponent = null;

  const menuItems: ItemType<MenuItemType>[] = [
    {
      icon: <UserOutlined />,
      label: <a href="/profile">Perfil</a>,
      key: "profile",
    },
    {
      icon: <SettingOutlined />,
      label: <a href="/settings">Configuración</a>,
      key: "settings",
    },
    {
      icon: <InfoCircleOutlined />,
      label: (
        <a
          href={
            externalLinks.userStudentSupportKobleTypeFormLink +
            userStudent.userStudentId
          }
          target="_blank"
          rel="noreferrer"
        >
          Ayuda
        </a>
      ),
      key: "help",
    },
    {
      type: "divider",
      key: "notifications-chat-divider",
    },
    {
      icon: <BellOutlined />,
      label: <a href="/notifications">Notificaciones</a>,
      key: "notifications",
    },
    {
      icon: <MessageOutlined />,
      label: <a href="/chat">Chats</a>,
      key: "chats",
    },
    {
      type: "divider",
    },
    {
      icon: <LogoutOutlined />,
      label: (
        <a href={`${config.NEXT_PUBLIC_SSO_FRONTEND}/sign-out`}>
          Cerrar sesión
        </a>
      ),
      key: "sign-out",
    },
  ];

  const getMenu = (mi: ItemType<MenuItemType>[]) => {
    return <Menu style={{ padding: 0, margin: 0 }} items={mi} />;
  };

  const popoverContent = (
    <div style={{ paddingBottom: 5 }}>
      <ConfigProvider
        theme={{
          components: {
            Menu: {
              itemMarginBlock: 0,
              itemMarginInline: 0,
            },
          },
        }}
      >
        <Row>
          <Col xs={0} md={24}>
            {getMenu(
              menuItems.filter(
                (item) =>
                  item?.key !== "notifications" &&
                  item?.key !== "chats" &&
                  item?.key !== "notifications-chat-divider"
              )
            )}
          </Col>
          <Col xs={24} md={0}>
            {getMenu(menuItems)}
          </Col>
        </Row>
      </ConfigProvider>
    </div>
  );
  avatarComponent = (
    <Popover
      content={popoverContent}
      overlayInnerStyle={{
        padding: 0,
        width: 250,
      }}
      key="user-avatar"
      title={
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingTop: 10,
            }}
          >
            <Avatar
              style={{
                backgroundColor: "#F5F5F5",
                marginLeft: 16,
              }}
              size="large"
              src={userStudent.profileImageUrl}
            >
              <UserOutlined style={{ color: "#606060", marginTop: 5 }} />
            </Avatar>
            <div style={{ padding: 10, paddingLeft: 10, paddingTop: 8 }}>
              <Title level={5} style={{ margin: 0 }}>
                {userStudent.firstName} {userStudent.lastName}
              </Title>
              <Text style={{ fontWeight: 400 }}>Estudiante</Text>
            </div>
          </div>

          {!userStudent.isProfileVisible && (
            <Space direction="vertical" style={{ marginLeft: 16 }} size={2}>
              <Text style={{ fontWeight: 400, margin: 0 }}>
                Tu perfil todavía no es público
              </Text>
              <a href="/onboarding">
                <Text style={{ fontWeight: 600, color: "#1677ff" }}>
                  Completar perfil
                </Text>
              </a>
            </Space>
          )}

          {userStudent.isProfileVisible && !userStudent.isProfileCompleted && (
            <Space direction="vertical" style={{ marginLeft: 16 }} size={2}>
              <Text style={{ fontWeight: 400, margin: 0 }}>
                Tu perfil aún puede ser mejorado
              </Text>
              <a href="/profile-completion">
                <Text style={{ fontWeight: 600, color: "#1677ff" }}>
                  Mejorar perfil
                </Text>
              </a>
            </Space>
          )}

          <Divider
            style={{
              marginTop: 8,
              marginBottom: 0,
              padding: 0,
            }}
          />
        </div>
      }
      placement="bottomRight"
      trigger={["click"]}
    >
      <div>
        <AvatarWithProgress
          size={40}
          percent={
            !userStudent.isProfileVisible ? visiblePercent : completedPercent
          }
          src={userStudent.profileImageUrl ?? undefined}
          showProgress={
            !userStudent.isProfileVisible
              ? visiblePercent < 100
              : completedPercent < 100
          }
          color={userStudent.isProfileVisible ? "#8e44ad" : undefined}
        />
      </div>
    </Popover>
  );

  return avatarComponent;
};

export default useUserStudentLayoutAvatar;
