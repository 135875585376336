import React from "react";
import { MessageOutlined, SearchOutlined } from "@ant-design/icons";
import { CommonLayoutMenuItemType } from "@koble/ui/src/CommonLayout/CommonLayoutContext";
import { CommonSymbology } from "@koble/ui/src/commonSymbology";

import useUserStudentProfile from "@/common/UserStudentProfileContext/useUserStudentProfile";

const useUserStudentLayoutUpperMenuItems = () => {
  const { userStudent } = useUserStudentProfile();
  const menuItems: CommonLayoutMenuItemType[] = [];

  menuItems.push({
    label: <a href="/explore">Explora</a>,
    key: "explore",
    icon: <SearchOutlined />,
  });

  menuItems.push({
    label: <a href="/likes-received">Interesados en mí</a>,
    key: "likes-received",
    icon: <CommonSymbology.Like />,
    badgeCount: userStudent?.likesReceivedCount || undefined,
  });

  menuItems.push({
    label: <a href="/matches">Matches</a>,
    key: "matches",
    icon: <CommonSymbology.Match />,
    badgeCount: userStudent?.matchCount || undefined,
  });

  menuItems.push({
    label: <a href="/chat">Chats</a>,
    key: "chats",
    icon: <MessageOutlined />,
    badgeCount: userStudent?.unreadChatsCount || undefined,
  });

  return menuItems;
};

export default useUserStudentLayoutUpperMenuItems;
