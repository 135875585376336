import React from "react";
import { useAuth, useChatWebSocket } from "@koble/hooks";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { Button, Divider, Popover, theme, Typography } from "antd";
import { useRouter } from "next/navigation";

import LoadingBox from "../LoadingBox";

import { CommonChatList } from "./index";

const { Title } = Typography;

const CommonChatPopover = ({ children }: { children: React.ReactNode }) => {
  const { identityClaims } = useAuth();
  const token = theme.useToken();
  // TODO: get these as parameters. This is a common component and should not have any dependencies.
  const { chats, isLoading, fetchData } = useChatWebSocket();
  const router = useRouter();
  const { isMobile } = useIsMobile();

  return (
    <Popover
      trigger={["click"]}
      onOpenChange={async (open) => {
        if (open) await fetchData();
      }}
      overlayInnerStyle={{
        padding: 0,
        paddingTop: 10,
        paddingBottom: 10,
      }}
      overlayStyle={{
        width: "100%",
        maxWidth: 450,
        paddingLeft: 10,
        paddingRight: 10,
      }}
      placement={isMobile ? "bottom" : "bottomRight"}
      title={
        <Title level={4} style={{ marginLeft: 15 }}>
          Chats
        </Title>
      }
      content={
        <>
          {isLoading ? (
            <LoadingBox minHeight={200} />
          ) : (
            <CommonChatList
              chats={chats}
              viewerUserId={identityClaims?.sub || ""}
              onSelectChat={(chatId) => {
                if (!chatId) {
                  router.push(`/chat`);
                  return;
                }
                router.push(`/chat/${chatId}`);
              }}
              isLoading={isLoading}
            />
          )}
          <Divider
            style={{
              marginBottom: 10,
              marginTop: 0,
            }}
          />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <a href="/chat">
              <Button type="link" style={{ color: token.token.colorPrimary }}>
                Ver todos los chats
              </Button>
            </a>
          </div>
        </>
      }
    >
      {children}
    </Popover>
  );
};

export default CommonChatPopover;
