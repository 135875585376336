import React from "react";
import { useTheme } from "@koble/hooks";
import { ConfigProvider, Layout, Menu } from "antd";

import useCommonLayout from "../useCommonLayout";

import {
  CommonLayoutSideBarCollapseTrigger,
  CommonLayoutSideBarLogo,
  CommonLayoutSideBarMenu,
  CommonLayoutSideBarVersionNumber,
} from "./index";

const { Sider } = Layout;

const CommonLayoutSideBar = () => {
  const { theme } = useTheme();
  const { sideBarWidth, setCollapsed, collapsed, lowerMenuItems } =
    useCommonLayout();

  return (
    <ConfigProvider theme={theme}>
      <Sider
        width={sideBarWidth}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: "#ffffff",
        }}
        theme="light"
        trigger={<CommonLayoutSideBarCollapseTrigger />}
        collapsible
        // breakpoint={"xxl"}
        defaultCollapsed={collapsed}
        onCollapse={(c) => {
          setCollapsed(c);
        }}
      >
        <CommonLayoutSideBarLogo />
        <div
          style={{
            height: `calc(100vh - ${
              theme?.token.Layout?.triggerHeight || 48
            }px - ${80}px)`,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CommonLayoutSideBarMenu />
          <div style={{ flexGrow: 1 }} />
          {lowerMenuItems && <Menu selectedKeys={[]} items={lowerMenuItems} />}
          <div style={{ marginLeft: 17, marginBottom: 10, marginTop: 10 }}>
            <CommonLayoutSideBarVersionNumber />
          </div>
        </div>
      </Sider>
    </ConfigProvider>
  );
};

export default CommonLayoutSideBar;
