import { MenuOutlined } from "@ant-design/icons";
import { useTheme } from "@koble/hooks";
import { Badge, ConfigProvider, Layout, Menu, Popover, Space } from "antd";
import { ItemType } from "antd/es/menu/interface";

import { CommonLayoutMenuItemType } from "../CommonLayoutContext";
import useCommonLayout from "../useCommonLayout";

const { Header } = Layout;
const CommonLayoutHeader = () => {
  const { theme } = useTheme();

  const {
    leftAddons,
    rightAddons,
    mobileLeftAddons,
    mobileRightAddons,
    avatarComponent,
    smallLogoUrl,
    upperMenuItemsWithBadges,
    isMobile,
  } = useCommonLayout();

  const anyBadges = upperMenuItemsWithBadges?.some(
    (item: ItemType<CommonLayoutMenuItemType>) =>
      (item as CommonLayoutMenuItemType).badgeCount
  );

  return (
    <ConfigProvider theme={theme}>
      <Header
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#ffffff",
          paddingLeft: 25,
          paddingRight: 25,
        }}
      >
        {isMobile && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 16,
            }}
          >
            <Popover
              content={
                <div>
                  <Menu
                    style={{ border: "none" }}
                    items={upperMenuItemsWithBadges}
                  />
                </div>
              }
              placement="bottomLeft"
              trigger={["click"]}
            >
              {anyBadges ? (
                <Badge dot offset={[-13, -1]}>
                  <MenuOutlined
                    id="common-layout-header-menu-icon"
                    style={{ marginRight: 15 }}
                  />
                </Badge>
              ) : (
                <MenuOutlined
                  id="common-layout-header-menu-icon"
                  style={{ marginRight: 15 }}
                />
              )}
            </Popover>

            <a
              href="/"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={smallLogoUrl} alt="Koble logo" height={65 / 2} />
            </a>
          </div>
        )}

        {isMobile ? (
          <Space size={16}>{mobileLeftAddons}</Space>
        ) : (
          <Space size={16}>{leftAddons}</Space>
        )}

        <div style={{ flexGrow: 1 }} />

        {isMobile ? (
          <Space size={16}>{mobileRightAddons}</Space>
        ) : (
          <Space size={16}>{rightAddons}</Space>
        )}

        <div style={{ marginLeft: 16 }}>{avatarComponent}</div>
      </Header>
    </ConfigProvider>
  );
};

export default CommonLayoutHeader;
