import React from "react";
import { Notification } from "@koble/graphql";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { Button, Divider, Empty, List, Popover, theme, Typography } from "antd";

import LoadingBox from "../LoadingBox";

import CommonNotificationListItem from "./CommonNotificationListItem";

const { Title } = Typography;

const CommonNotificationPopover = ({
  notifications,
  isLoading,
  children,
  fetchData,
}: {
  notifications: Notification[];
  isLoading: boolean;
  children: React.ReactNode;
  fetchData: () => Promise<void>;
}) => {
  const token = theme.useToken();
  const { isMobile } = useIsMobile();

  return (
    <Popover
      trigger={["click"]}
      onOpenChange={async (open) => {
        if (open) await fetchData();
      }}
      overlayInnerStyle={{
        padding: 0,
        paddingTop: 10,
        paddingBottom: 10,
      }}
      overlayStyle={{
        width: "100%",
        maxWidth: 450,
        paddingLeft: 10,
        paddingRight: 10,
      }}
      placement={isMobile ? "bottom" : "bottomRight"}
      title={
        <Title level={4} style={{ marginLeft: 15 }}>
          Notificaciones
        </Title>
      }
      content={
        <>
          {notifications.length === 0 && !isLoading && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No tienes notificaciones"
            />
          )}

          {isLoading ? (
            <LoadingBox minHeight={200} />
          ) : (
            notifications.length > 0 && (
              <>
                <List
                  style={{ maxHeight: "50vh", overflowY: "auto" }}
                  itemLayout="horizontal"
                  dataSource={notifications}
                  renderItem={(item) => (
                    <CommonNotificationListItem notification={item} />
                  )}
                />
                <Divider
                  style={{
                    marginBottom: 10,
                    marginTop: 0,
                  }}
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <a href="/notifications/">
                    <Button
                      type="link"
                      style={{ color: token.token.colorPrimary }}
                    >
                      Ver todas las notificaciones
                    </Button>
                  </a>
                </div>
              </>
            )
          )}
        </>
      }
    >
      {children}
    </Popover>
  );
};

export default CommonNotificationPopover;
