import useCommonLayout from "../useCommonLayout";

const CommonLayoutSideBarLogo = () => {
  const { collapsed, smallLogoUrl, largeLogoUrl } = useCommonLayout();

  return (
    <div
      style={{
        display: "flex",
        // height: logoHeight,
        height: 80,
        alignItems: "center",
        paddingLeft: 23,
      }}
    >
      <a href="/">
        <img
          src={collapsed ? smallLogoUrl : largeLogoUrl}
          alt="Koble logo"
          height={collapsed ? 65 / 2 : 65 / 2}
          style={{
            marginTop: 13,
          }}
        />
      </a>
    </div>
  );
};

export default CommonLayoutSideBarLogo;
