import React, { useState } from "react";
import { useAuth } from "@koble/hooks";
import { useIsMobile } from "@koble/hooks/src/IsMobile";
import { CommonUnsettledLikesReceivedBanner } from "@koble/ui";
import { BannerItemType } from "@koble/ui/src/CommonLayout/CommonLayoutContext";
import Cookies from "js-cookie";

import CommonCompletionProfileBanner from "@/common/CommonCompletionProfileBanner";
import CommonOnboardingBanner from "@/common/CommonOnboardingBanner";
import useUserStudentProfile from "@/common/UserStudentProfileContext/useUserStudentProfile";

const useUserStudentLayoutBanners = (closedBanners?: string) => {
  const [onboardingBannerClosed, setOnboardingBannerOnboardingBannerClosed] =
    useState(false);
  const [profileCompleteBannerClosed, setProfileCompleteBannerClosed] =
    useState(false);

  const { isMobile } = useIsMobile();
  const { identityClaims } = useAuth();
  const { userStudent } = useUserStudentProfile();

  const banners: BannerItemType[] = [];
  const hasBorderRadius = !userStudent?.likesReceivedCount;

  // return empty array if identityClaims is not present
  if (!identityClaims || !userStudent) return banners;

  // Retrieve the closedBanners cookie and parse it as an object
  const closedBannersDictionary = JSON.parse(closedBanners || "{}") as {
    [banner: string]: string;
  };

  const isBannerClosed = (bannerName: string) => {
    if (closedBannersDictionary) {
      const currentDate = new Date();
      const closedAt = new Date(closedBannersDictionary[bannerName]);

      // get if the difference is less than 10 minutes
      const difference = currentDate.getTime() - closedAt.getTime();
      return difference < 10 * 60 * 1000;
    }
    return false;
  };

  const onBannerClose = (bannerName: string) => {
    const currentDate = new Date();
    currentDate.setMinutes(currentDate.getMinutes() + 10);

    closedBannersDictionary[bannerName] = currentDate.toISOString();

    Cookies.set("closedBanners", JSON.stringify(closedBannersDictionary));

    switch (bannerName) {
      case "onboarding":
        setOnboardingBannerOnboardingBannerClosed(true);
        break;
      case "profileCompletion":
        setProfileCompleteBannerClosed(true);
        break;
      default:
        break;
    }
  };

  if (
    !userStudent?.isProfileVisible &&
    !isBannerClosed("onboarding") &&
    !onboardingBannerClosed
  ) {
    banners.push({
      component: (
        <CommonOnboardingBanner
          onOnboardingBannerClose={() => onBannerClose("onboarding")}
          hasBorderRadius={hasBorderRadius}
          isMobile={isMobile}
        />
      ),
      height: 42,
    });
  }

  if (
    userStudent?.isProfileVisible &&
    !userStudent?.isProfileCompleted &&
    !isBannerClosed("profileCompletion") &&
    !profileCompleteBannerClosed
  ) {
    banners.push({
      component: (
        <CommonCompletionProfileBanner
          onProfileCompletionBannerClose={() =>
            onBannerClose("profileCompletion")
          }
          hasBorderRadius={hasBorderRadius}
          isMobile={isMobile}
        />
      ),
      height: 42,
    });
  }

  if (userStudent?.likesReceivedCount && userStudent.likesReceivedCount > 0) {
    banners.push({
      component: (
        <CommonUnsettledLikesReceivedBanner
          likesReceivedCount={userStudent.likesReceivedCount}
          isMobile={isMobile}
          userType="userStudent"
        />
      ),
      height: 42,
    });
  }

  return banners;
};

export default useUserStudentLayoutBanners;
