import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";

import useCommonLayout from "../useCommonLayout";

const CommonLayoutSideBarCollapseTrigger = () => {
  const { collapsed } = useCommonLayout();

  return (
    <div
      style={{
        paddingLeft: 10,
        paddingRight: 10,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Button
        type="text"
        style={{
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.06)",
          border: "none",
        }}
        size="large"
      >
        {collapsed ? (
          <RightOutlined style={{ fontSize: 12 }} />
        ) : (
          <LeftOutlined style={{ fontSize: 12 }} />
        )}
      </Button>
    </div>
  );
};

export default CommonLayoutSideBarCollapseTrigger;
