import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { assets } from "@koble/assets/assets";
import { Col, Row, theme, Typography } from "antd";

const CommonOnboardingBanner = ({
  onOnboardingBannerClose,
  isMobile,
  hasBorderRadius,
}: {
  onOnboardingBannerClose: () => void;
  isMobile: boolean;
  hasBorderRadius: boolean;
}) => {
  const { token } = theme.useToken();

  return (
    <div
      key="onboarding-banner"
      style={{
        padding: "10px 15px",
        backgroundColor: token.colorPrimary,
        color: "white",
        borderBottomLeftRadius: hasBorderRadius ? 10 : 0,
        borderBottomRightRadius: hasBorderRadius ? 10 : 0,
      }}
    >
      <Row style={{ justifyContent: "space-between" }}>
        <Col style={{ display: "flex", alignItems: "center" }}>
          <img alt="Kobot" height={20} src={assets.KOBOT_HAPPY_EXTRA_SMALL} />

          {isMobile ? (
            <Typography.Text style={{ color: "white", marginLeft: 10 }}>
              ¡Aún no eres visible!
            </Typography.Text>
          ) : (
            <Typography.Text style={{ color: "white", marginLeft: 10 }}>
              ¡Aún no eres visible! Completa tu perfil para serle visible a los
              reclutadores
            </Typography.Text>
          )}
        </Col>

        <Col style={{ display: "flex", alignItems: "center" }}>
          <a href="/onboarding" style={{ marginRight: 10 }}>
            {isMobile ? (
              <Typography.Text style={{ color: "white" }}>
                Completar perfil
              </Typography.Text>
            ) : (
              <Typography.Text style={{ color: "white" }}>
                Completar mi perfil
              </Typography.Text>
            )}
          </a>
          <CloseOutlined
            style={{ cursor: "pointer" }}
            onClick={onOnboardingBannerClose}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CommonOnboardingBanner;
